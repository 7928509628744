<script setup>

</script>

<template>
  <div class="building-com">
    建设中...
  </div>
</template>

<style scoped>
  .building-com{
    position: fixed;
    left: 50%;
    top: 50%;
    width: 240px;
    height: 120px;
    transform: translate(-50%, -50%);
    background-color: #5C69E3BB;
    color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    backdrop-filter: blur(4px);
    box-shadow: 2px 2px 8px #00000011;
  }
</style>