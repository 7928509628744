<template>
  <div class="comment-container">
    <a-divider/>
    <h1>评论</h1>
    <p>暂不支持</p>
  </div>
</template>

<script setup>

</script>

<style scoped>
  .comment-container{
    width: 100%;
    padding: 0 24px 12px 24px;
  }
</style>
